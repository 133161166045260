.pdp-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
  margin-top: 10rem;
}
.carousel-container {
  display: flex;
  width: 50%;
  justify-content: center;
}
.carousel-container-container {
  width: 45vw;
  height: 34vw;
  display: flex;
  justify-content:center ;
  align-items: center;
}
.pdp-details-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.pdp-content {
  display: flex;
  justify-content: center;
  /* margin-top: 10vw; */
}
.pdp-title {
  margin-top: 0;
  width: 39rem;
  text-align: end;
}
.pdp-product-size {
  font-size: 30px;
}
.pdp-details {
  display: flex;
  margin-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  gap: 2rem;
}
.pdp-accordion {
  width: 100%;
  border: none;
}
.accordion-title {
  color: white;
  background-color: transparent;
}
.accordion-title:focus,
.accordion-title:hover {
  background-color: transparent;
  box-shadow: none;
}
.pdp-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vw;
  gap: 1rem;
}
.acrousel-hidden {
  display: none;
}

div#Luz\ LedIluminación0, div#TransformadorIluminación, div#Luz\ LedIluminación1 {
  height: auto !important;
}

.image-container, .image-container img {
  object-fit: cover;
  height: 100%;

}
.image-container img {
  width: 100%;

}

@media (max-width: 768px) {
  div#Luz\ LedIluminación0 img, div#TransformadorIluminación img, div#Luz\ LedIluminación1 img {
    width: 100% !important;
    height: 100% !important;
}
  .image-container {
    width: 100%;
    
  }
  .image-container img {
    height: 19rem;
    width: 100%;
  
  }
  .pdp-container {
    flex-direction: column;
    margin-top: 9rem;
  }
  .carousel-container {
    width: 100%;
  }
  .carousel-container-container {
    width: 100%;
    height: 19rem;
    max-height: 30rem;
  }
  .pdp-details {
    width: 100%;
  }
  .pdp-details-container {
    width: 100%;
    align-items: flex-start;
  }
  .pdp-title {
    margin: 1rem 0 1rem 0;
    font-size: 37px;
    width: auto;
    text-align: start;
  }
  .pdp-product-size {
    font-size: 26px;
    width: 100%;
    margin: 1rem 0 1rem 0;
  }
  /* .product-button{
    width: auto;
} */
}

@media (max-width: 425px) {

  .pdp-buttons {
    flex-direction: column;
  }
  .pdp-buttons a ,.pdp-buttons button {
    width: 100% !important; 
    font-size: 16px !important;
    height: 46px !important;
  }
}
/* @media (max-width: 320px) {

  span.flex.items-center.transition-all.duration-200.rounded-md.text-sm.px-4.py-2 {
    font-size: 0.8rem !important;
  }
} */
