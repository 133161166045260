input:-webkit-autofill,input:-webkit-autofill:focus  {
    -webkit-box-shadow: 0 0 0 50px var(--main-black) inset;
    -webkit-text-fill-color: rgb(255, 255, 255) ;
    border: 1px solid white;

}
.dropdown, .dropbtn{
    height: 100%;
}
.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor:pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  
  /* Dropdown Content (Hidden by Default) */
  .col-title{
    font-weight: 700;
    font-size: 18px;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--main-black);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    left: 0;
    width: 100%;
    height: 20rem;
    padding:2rem;

  }
  .dropdown-content ul {
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
    gap: 1vw;
  }
  .dropdown-content a{
    width: fit-content !important;
    cursor: pointer;
    height: auto !important;
  }

  .dropdown-content a:hover, .il-selected {
    border-bottom: 1px solid white;
    margin-bottom: -1px;
  }
  .dropdown-categories{
    width: 10%;
    border-right: 1px solid white;
    margin-left: 10vw !important;
  }

  .col1, .col2 {
    width: 15%;
  }

  .dropdown:hover .dropdown-content, .dropdown-content:hover .dropdown-content  {display: flex;}
  .menu-img{
    width: 500px;
    max-height: 250px !important;
    object-fit: cover;
  }
  .col-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 0 !important;
  }
header, .header-container{
    width: 100%;
    display:flex;
    flex-direction: row;
    height: 7.4rem;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.20);
   position: fixed;
    border-bottom: 1px solid white;
    font-size: 16px;
    z-index: 2;
    top: 0;
}

.header-nav {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:3vw;
    height: 100%;
}
.header-nav-color{
    background-color: var(--main-black) !important;
}
.header-nav a {
    width: auto;
}
a{
    display: flex;
    height: 5vh ;
    align-items: center;
    text-decoration: none;
    color:white;
}
.logo {    
    height: 5rem;
}


.header-button:hover:enabled, .header-button:active, .header-button:focus, .header-button:hover,
.product-button:hover:enabled, .product-button:active, .product-button:focus, .product-button:hover{
    background-color: transparent;
    box-shadow: none;
}
.header-button:focus-visible, .product.button:focus.visible {
    background-color: transparent !important;
    border: 1px solid white;
    outline: none !important;
    
}
.form-container, .form-input input, .form-input-details input{
    background-color: var(--main-black);
}

.form-container:last-child{
    display: flex;
    justify-content: center;
}
.form-input input, .form-input-details input{
    margin-bottom: 1rem;
}
.form-input input{
    width: 21vw;
    color: white;
}
.confirmation-message{
   margin-top: 1rem;
}
.confirmation-message-container{
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
 }
.form-text{
    color:white;
}
.form-text:focus{
    background-color: transparent;

}
.form-button, .form-button:hover:enabled {
    color: black;
    background-color: white;
}
.form div{
    max-width: 65vw;
}
.form-input-details{
    margin-top:1rem;
    width: 100%;
    height: 10rem;
    color:white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0.5rem;
}
.form-input-products input{
    background-color: transparent;
    color:white;
}
.form-title{
    font-size: 20px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.form-row{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

}
/* .form-row input{
    width: 100%;

} */
.form-btn-container{
    display: flex;
    /* width: 100%; */
    justify-content: flex-end;
}
.bold{
    font-weight: bold;
}
.form-container svg{
    width: 3rem;
    height: 3rem;
}
.form-header button:hover{
    background-color: transparent;
    color: white;
    
}
.form-header{
    padding: 2rem 5rem 0 0;
}
.lupaIcon{
    width: 1.6rem;
}
.search-container{
    height: 5vh;
    display:flex;
    align-items:center;
    border: 1px solid white;
    border-radius: 8px;
    gap:0.5rem;
    padding-right: 0.5rem ;
}
.search-input {
    height: 5vh;
    background-color: transparent;
    border:none ;
    border-right:  1px solid white;
}
.search-input:focus{
    box-shadow: none;
    border-right:  1px solid white;
}
.search-input::-webkit-search-cancel-button {
    display:none;
}

*:focus-visible{
    border:  none;
    box-shadow: none;
}
.hidden{
    margin-top: 0rem;
    height: 0rem;
}
.div-form-div{
    width: 40%;
}
.hamburger-icon{
    max-width: 1.4rem;
}
header:focus, header:hover{
    background-color: var(--main-black);
}

.black{
    background: var(--main-black);
}

/* div#\:r2\: {
    background: black;
    border: none;
} */
.dropdown-container div{
    background-color: var(--main-black);
    border:none;
}

.header-nav button {
    background: transparent !important;
}

.header-nav button:focus, .header-nav button:focus-visible {
    box-shadow: none;
    border: 1px solid white;
}
.dropdown-container button:focus, .dropdown-container button:focus-visible {
    box-shadow: none;
    border: none;
}

span.flex.items-center.transition-all.duration-200.rounded-md.text-sm.px-4.py-2 {
    font-size: 16px;
}
button.flex.items-center.justify-start.py-2.px-4.text-sm.text-gray-700.cursor-pointer.w-full.hover\:bg-gray-100.focus\:bg-gray-100.dark\:text-gray-200.dark\:hover\:bg-gray-600.focus\:outline-none.dark\:hover\:text-white.dark\:focus\:bg-gray-600.dark\:focus\:text-white {
    font-size: 16px;
    color: white;
    padding-top: 0;
    padding-bottom: 0;
}
.header-desktop{
    display:flex
}
.header-mobile{
    display:none;
}
.header-button{
    width: 10rem;
    padding: 0;
}

@media (max-width:768px){
    .py-5.px-5.last\:rounded-b-lg.dark\:bg-gray-900.first\:rounded-t-lg{
        max-height: 23rem !important;
    }
    .header-desktop{
        display:none;
    }
    .header-mobile{
        display:flex;
    }
    header{
        height: 5rem;
    }
    .header-button{
        border: none;
        border-radius: 8px;
        color: white;
        height: 5vh;
        background-color: transparent;
        box-shadow: none;
    }
    .header-container{
        margin-top: 5rem;
        height: auto;
        background: var(--main-black);
        /* transition: all 1s ease-out; */
    }
    .logo{
        height: 3rem;
    }
    .search-container{
        padding-right: 0.8rem;
        max-width: 9rem;
        max-height: 2rem;

    }  
    .search-input{
        max-width: 86%;
        max-height: 2rem;

    }    
    .lupaIcon {
        max-width: 1rem;
    }
    .form-input input{
        width: 100%;
    }
    .div-form-div{
        width: 100%;
    }
    .form div{
        max-width: 100%;
        width: 100%;
    }
    .form-header{
        padding: 0;
    }
    .form-title{
        display: block;
    }
    .form-row {
        gap:0;
    }
    .header-container button.flex.w-full.items-center.justify-between.first\:rounded-t-lg.last\:rounded-b-lg.py-5.px-5.text-left.font-medium.text-gray-500.dark\:text-gray-400.hover\:bg-gray-100.focus\:ring-4.focus\:ring-gray-200.dark\:hover\:bg-gray-800.dark\:focus\:ring-gray-800.accordion-title {
        padding: 0 !important;
        height: 5vh;
    }
    .header-container .py-5.px-5.last\:rounded-b-lg.dark\:bg-gray-900.first\:rounded-t-lg {
        padding-left: 1rem;
        border:none;
        padding-top:0;

    }
    .header-container button.flex.w-full.items-center.justify-between.first\:rounded-t-lg.last\:rounded-b-lg.py-5.px-5.text-left.font-medium.hover\:bg-gray-100.focus\:ring-4.focus\:ring-gray-200.dark\:hover\:bg-gray-800.dark\:focus\:ring-gray-800.text-gray-900.bg-gray-100.dark\:bg-gray-800.dark\:text-white.accordion-title {
        padding: 0!important;
      
    }
    .header-container span.flex.items-center.transition-all.duration-200.rounded-md.text-sm.px-4.py-2 {
      padding: 0;  
      font-size: 16px !important;
      font-weight: 300 !important;

    }
    .header-container button.group.flex.items-center.justify-center.p-0\.5.text-center.font-medium.relative.focus\:z-10.focus\:outline-none.text-white.bg-cyan-700.border.border-transparent.enabled\:hover\:bg-cyan-800.focus\:ring-cyan-300.dark\:bg-cyan-600.dark\:enabled\:hover\:bg-cyan-700.dark\:focus\:ring-cyan-800.rounded-lg.focus\:ring-2.header-button {
        padding: 0;
        justify-content: start;
    }
    /* .header-accordion-text{
        margin-top: 1rem;
    } */
    .additional-text{
        padding: 1rem;
        width: 19rem;
    }

}