
/* @media (max-width:768px){ */
    .areatecnica-container{
        margin-top: 7rem;
        justify-content: center;
    }
    .areatecnica-title{
        margin:1rem;
        text-align: center;
    }
    .areatecnica-text{
        display: flex;
        flex-direction: column;
        margin: 1rem;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        
    }
    .areatecnica-subtitle{
        font-size: 26px;
    }
    .areatecnica-button{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        border-radius: 8px;
        color: white;
        font-size: 15px;
        background-color: transparent;
        padding: 0.5rem;
    }
 
@media (min-width:768px){
    .video-text-container{
    display: flex;
    flex-direction: row-reverse;
    padding-left: 12rem;
    padding-right: 12rem;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    }
    video{
        min-width: 10rem !important;
        min-height: 40rem !important;
        max-width: 20rem !important;
        max-height: 40rem !important;
    }
    .areatecnica-text{
        width: 56%;
        
    }
    .areatecnica-container{
        margin-top: 9rem;
    }

}