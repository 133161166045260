/* body{
  background-color: rgb(125, 125, 125);
} */
.App {
  text-align: center;
}

h1{
  margin-top: 8rem;
  font-size: 52px;
}
.banner, .banner-mobile{
  width: 100%;
  height: 35rem;
  object-fit: cover;
  object-position: top;
  /*margin-top: 7.4rem;*/
}

/* .banner-mobile{
  width: 100%;
  height: 58rem;
  object-fit: cover;
} */
.descripcion{
  height: 8rem;
  font-size: 18px;
}
.body-container{
  display: flex;
  justify-content: center;

}
.product-container{
  width: 80%;
}
.product-card-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
.carousel-home{
  height: 70rem;
} 
svg.h-5.w-5.text-white.dark\:text-gray-800.sm\:h-6.sm\:w-6 {
  color:black;
}
.App-link {
  color: #61dafb;
}
.carousel-desktop{
  display: flex;
  height: 35rem;
  margin-top: 7.4rem;
}
.carousel-mobile{
  display:none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notfound-container-container{
  display: flex;
    height: 28rem;
    text-align: center;

}
.notfound-container{
  margin:auto;

}
.seguridad-datos-container{
  margin: 2rem;
  width: 90%;
}

@media (max-width:768px){

  .carousel-mobile{
    display:block;
    height: 13rem;
    margin-top: 4.5rem;
  }
  .carousel-desktop{
    display: none;
  }

  .banner-mobile{
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .carousel-desktop{
    margin-top:5rem;
  }
  .banner{
    margin-top:0 !important;
  }
  
  .carousel-home, .banner, .banner-mobile,  .carousel-desktop{
    height: 13rem;
  } 

}
@media (max-width:425px){
  .banner-mobile{
    object-fit:unset;
  }

}
@media (min-width:768px){
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-thumb{
  background-color:rgba(255, 255, 255, 0.988);

}
}