

.instagram-chat, .facebook-chat, .iconChat{
    /*position:fixed;*/
    z-index:999;
    right: 2%;
    bottom: 4%;
    padding:0;
    height: 61px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
}

.chat-icons-container, .iconChat{
    display: flex;
    position: fixed;
    right: 2.5%;
    bottom: 1%;
    flex-direction: column;
    gap:10px;
    bottom:4%;
}

.chat-icons-container{
    z-index: 999;
    bottom: 5.5rem;
}
.iconChat{
    z-index: 9999;
}

.facebook-chat{
    right: 2%;
    bottom: 12%; 
}

.instagram-chat img, .facebook-chat img{
    border-radius: 50%;
    width: 40px;
}
.iconChat img{
    padding:4px;
}

.iconChat img{
    border-radius: 50%;
    width: 40px;
    background-color: white;
}


@media (max-width:1024px){
    .instagram-chat{
        right:1%;
        bottom:1%;
      
    }
    .facebook-chat {
        right: 1%;
        bottom: 9%;
    }
}

.hidden-chat {
    display: none;
}