.catalogue-container-container{
    display:flex;
    justify-content: center;
    width: 100%;
    height: 40vw;
  
}
.catalogue-container{
    display: flex;
    margin-top: 7.5rem;
    width: 100%;
    height: 100%;
    background-image: url("../assets/SPC_BACK\ 1.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 2rem;
}
.catalogue-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 25px;
    background: rgba(0, 0, 0, 0.37);
    width: 14vw;
    height: 6vh;
}
.calatogue-span, .catalogue-2nd-span{
    color:black;
    font-size: 25px;
}
.catalogue-2nd-span{
    font-weight: bold;
}
.catalogue-btn-span{
    display: flex;
    gap: 1vw;
    flex-direction: column;
    width: 22rem;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem 1.5rem 0;

}

@media (max-width:768px){
    .catalogue-container-container{
        height: auto;
    }
    .catalogue-container{
        margin-top: 5rem;
    padding: 0;
    width: 100%;
    /* padding: 9rem; */
    height: 120vw;
    padding: 1rem;
    background-size: 200%;
    }
    .catalogue-btn-span{
        width: 100%;
    }
    .calatogue-span, .catalogue-2nd-span {
        font-size: 20px;
    }
    .catalogue-button{
        font-size: 20px;
        padding: 1rem;
        width: auto;
    }

}