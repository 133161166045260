footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid white;
  margin-top: 12rem;
  height: 24rem;
  align-items: center;
}
.nav-servicios,
.nav-2nd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}
.nav-servicios a,
.nav-2nd a{
  color:transparent

}
.nav-servicios a,
.nav-2nd {
  display: flex;
  justify-content: flex-start;
}

.footer-logo {
  height: 8rem;
  max-width: 15rem;
}
.footer-title {
  font-weight: 900;
}
footer a {
  height: 3vh;
}
.footer-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-icon {
  width: 30px;
  height: 30px;
}
.footer-allicons {
  display: flex;
  justify-content: space-around;
  margin-top: 2.4rem;
  gap: 1rem;
}
.copyright-spam{
  text-align: center;
  margin: 2rem;
}
.footer-bottom{
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  footer {
    margin-top: 7rem;
    height: 23rem;
    flex-direction: column-reverse;
    padding: 2rem;
  }

  .nav-servicios, .nav-2nd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.footer-images{
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-allicons{
    gap: 0.5rem;
    width: 100%;
    margin-top: 1.3999999999999986rem;
}

.footer-logo{
    max-height: 4rem;
}
.footer-bottom{
  flex-direction: column;
    align-items: center;
}
}
