p{
    margin:5px
}
.card-container {
    display: flex;
    flex-direction: column;
    width: 24rem;
    border-radius: 30px;
    border: 1px solid white;
    min-height: 35rem;
     max-height: 35.5rem;
}
.product-image {
    width: 100%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 77% !important;
    object-fit: cover !important;
    min-height: 26.5rem;

}
.product-name{
    font-size: 18px;
}
.card-text-button{
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
}
.product-category{
    font-size: 25px;
    font-weight: 900;

}
#image-20{
    object-fit: contain !important;
}

.product-button, .header-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    background-color: transparent;
    width: auto;
    padding: 1rem;
    height: 3rem;
}

@media (max-width:720px){
    .card-text-button{
        padding: 1rem ;
    }
    .product-image {
        min-height: 18.5rem;
        max-height: 16.5rem;
    }
    .card-container {
        min-width: 18rem;
        max-width: 18rem;
        min-height: 24.5rem;
        max-height: 22.5rem;
    }
    .product-category{
        font-size: 16px;
    }
    .product-name{
        font-size: 16px;
    }
    .product-button{
        width: 8rem;
        height: 3rem !important;
    }
}
@media (max-width:320px){
    .product-image {
        min-height: 16.5rem;
        max-height: 16.5rem;
    }
    .card-container {
        min-width: 14rem;
        max-width: 14rem;
        min-height: 22.5rem;
        max-height: 22.5rem;
    }
    .product-category{
        font-size: 0.8rem;
    }
    .product-name{
        font-size: 0.8rem;
    }
    .product-button{
        width: 7rem;
        height: auto !important;
        font-size: 0.8rem;
    }
}