.plp-product-container{
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:5vw;
    justify-content: center;
    margin-top:9rem;
}
.plp-image-container{
    position: relative;
}
.plp-image-container img{
    height: 30vw;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
}
.plp-image-container span:first-child{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index:1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    font-size: 3rem;
    letter-spacing: 0.1rem;
}
.image-subtitle{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index:1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    font-size: 2rem;
    letter-spacing: 0.05rem;
}
.py-5.px-5.last\:rounded-b-lg.dark\:bg-gray-900.first\:rounded-t-lg { 
    overflow-y: scroll;
    max-height: 12rem; 
}

.filter-container{
    width: 30%;
    height: 29rem;
    position: sticky;
    top: 0;

}
.plp-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.plp-accordion{
    margin-top:10rem;
    width: 90%;
}

.filter-button {
    display: none;
}
.filter-selected{
    text-decoration: underline;
    text-underline-offset: 4px;
}
@media (max-width: 1024px) {
    .plp-image-container img{
        height: 30rem;
        aspect-ratio: 9/16;
    }
}
@media (max-width: 768px) {

    .plp-product-container{
        margin-top: 4rem;
    }
  
    .plp-accordion-desktop{
        display: none;
    }
    .filter-button {
        display: flex;
    }
    .filter-button{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: white;
        font-size: 20px;
        width: 100%;
        height: auto;
        z-index: 2;
        gap: 0.2rem;
    }
    
    .filter-container{
        background: var(--main-black);
        top: 5rem;
        height: auto;
        width: 100%;
        border-bottom: 1px solid white;
        padding: 0.5rem;
        padding-left: 2.3rem;
        z-index:1;
    }
    /* .plp-accordion{
        display:none
    } */
    .filter-svg{
        width: 1.2rem;
    }
    .plp-container{
        flex-direction: column;
justify-content: space-around;
align-items: center;
    }
    .plp-accordion{
        width: 100%;
        margin-top: 0;
    }
    .plp-product-container{
        width: 80%;
        gap: 10vw;
    }
    .product-button{
        height: 8vh;
        padding: 0.5rem;
    }
  
    .plp-accordion-mobile{
        flex-direction: column;
        gap: 1rem;
    }
    .plp-accordion-title-mobile{
        font-size: 25px;
        margin: 1rem;

    }
    .relative.rounded-lg.bg-white.shadow.dark\:bg-gray-700.flex.flex-col.max-h-\[90vh\] {
        height: 100%;

    }
    .plp-accordion-mobile-btn-container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .card-container{
        margin-top:3rem;
    }
    .plp-image-container span:first-child{
        font-size: 2rem;
    }
    .image-subtitle{
        font-size: 1.4rem;
    }

}