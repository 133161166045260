@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root{
  --main-black: #121212;
}
body {
  margin: 0;
  font-family: 'Avenir';
  color: white;
  background-color:var(--main-black);
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
